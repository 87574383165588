(
    function ($) {
        'use strict';

        var Site = {
            $body: $('html'),

            /**
             * Init call.
             */
            init: function () {
                this.initSubscribeForm();
            },

            initSubscribeForm: function () {
                $('#fullname').attr('placeholder', 'Name');
                $('#email').attr('placeholder', 'Email');
            }
        };

        window.onload = function () {
            Site.init();
        };
    }(jQuery)
);
